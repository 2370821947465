import * as React from 'react'
import { CallToActionComponentProps } from '../CallToAction'
import Image from '../../Image'
import Link from '../../Link'

export const CallToActionBanner = ({
  className,
  iconImage,
  iconImageAlt,
  backgroundImage,
  backgroundImageAlt,
  title,
  description,
  button,
  children,
}: CallToActionComponentProps) => {
  return (
    <div className={className}>
      <div className="relative">
        <div className="absolute inset-0">
          {backgroundImage && (
            <Image
              className="h-full w-full"
              image={backgroundImage}
              alt={backgroundImageAlt || ''}
            />
          )}
        </div>
        <div className="relative container py-5 md:py-8">
          <div className="sm:flex justify-center">
            {iconImage && (
              <div className="hidden sm:flex items-center mx-8">
                <Image image={iconImage} alt={iconImageAlt || ''} />
              </div>
            )}
            <div>
              <div className="text-2xl font-bold text-white">{title}</div>
              <div className="md:flex md:items-center">
                <div className="text-white pt-3 md:mr-8 lg:mr-20">
                  {description && (
                    <div
                      className="prose-basic"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  )}
                </div>
                <div className="mt-5 md:-mt-10">
                  {children
                    ? children
                    : button && (
                        <Link className="Button --white" item={button}>
                          {button.label}
                        </Link>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
