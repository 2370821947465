import * as React from 'react'
import Image from '../../Image'
import { CallToActionComponentProps } from '../CallToAction'
import Link from '../../Link'
import cn from 'classnames'

export const CallToActionRightContent = ({
  className,
  backgroundImage,
  backgroundImageAlt,
  title,
  description,
  button,
}: CallToActionComponentProps) => {
  return (
    <div className={cn('container', className)}>
      <div className="relative -mx-4 sm:mx-0">
        <div className="absolute inset-0">
          {backgroundImage && (
            <Image
              className="h-full w-full opacity-40 md:opacity-100"
              image={backgroundImage}
              alt={backgroundImageAlt || ''}
              objectPosition="left"
            />
          )}
        </div>
        <div className="relative flex md:justify-end">
          <div className="sm:w-5/6 md:w-1/2 pt-4 pb-6 px-4 md:pl-0 md:pr-5">
            {title && (
              <h1 className="flex-shrink text-2xl font-semibold text-brand-dark-blue">
                {title}
              </h1>
            )}
            {description && (
              <div
                className="mt-3 mb-5 font-medium text-lg prose-basic"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}

            <div>
              {button && (
                <Link className="Button --light" item={button}>
                  {button.label}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
