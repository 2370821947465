import * as React from 'react'
import { graphql } from 'gatsby'
import Image from '../Image'
import Link from '../Link'
import cn from 'classnames'

type CardProps = {
  className?: string
  variant?: Maybe<string>
  image: Maybe<DirectusImage>
  image_alt?: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  button_link: Maybe<DirectusLink>
  children?: React.ReactNode
}

export const Card = ({
  className,
  variant,
  image,
  image_alt,
  title,
  description,
  button_link,
  children,
}: CardProps) => {
  return (
    <div className={className}>
      <div className="container">
        <div
          className={cn(
            'xl:px-20 flex flex-col md:flex-row',
            variant === 'reverse' ? 'md:flex-row-reverse' : '',
          )}
        >
          <div
            className={cn(
              'md:w-2/5',
              variant === 'reverse' ? 'md:ml-5 lg:ml-10' : 'md:mr-5 lg:mr-10',
            )}
          >
            <Image
              className="w-full md:h-full"
              image={image!}
              alt={image_alt || ''}
            />
          </div>
          <div className="md:w-3/5 mt-5 md:mt-0 md:pt-1">
            <div className="mb-2 text-2xl font-bold text-brand-dark-blue">
              {title}
            </div>
            {description && (
              <div
                className="prose md:prose-sm lg:prose-base"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {(children || button_link) && (
              <div className="mt-4">
                {children
                  ? children
                  : button_link && (
                      <Link className="Button --light" item={button_link}>
                        {button_link.label}
                      </Link>
                    )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment CardComponent on DirectusData_card_components {
    variant
    image {
      id
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    image_alt
    title
    description
    button_link {
      ...DirectusLink
    }
  }
`
