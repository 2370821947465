import * as React from 'react'
import Image from '../../Image'
import { CallToActionComponentProps } from '../CallToAction'
import Link from '../../Link'
import cn from 'classnames'

export const CallToActionHero = ({
  className,
  backgroundImage,
  backgroundImageAlt,
  iconImage,
  iconImageAlt,
  title,
  description,
  button,
}: CallToActionComponentProps) => {
  return (
    <div className={cn('container', className)}>
      <div className="relative -mx-4 sm:mx-0">
        <div className="absolute inset-0">
          {backgroundImage && (
            <>
              <Image
                className="h-full w-full"
                image={backgroundImage}
                alt={backgroundImageAlt || ''}
                loading="eager"
              />
              <div className="xl:hidden absolute inset-0 bg-gray-300 mix-blend-multiply" />
            </>
          )}
        </div>
        <div className="relative sm:pl-6 md:pl-10 py-4 md:py-10 lg:py-16 xl:py-20 xl:pl-16 xl:w-1/2 backdrop-blur-sm xl:backdrop-blur-none">
          <div className="sm:border-l-[8px] border-brand-dark-blue px-4 xl:pr-0 py-1.5 md:w-4/5 xl:w-auto">
            <div className="sm:flex sm:items-center">
              {iconImage && (
                <div className="float-left mr-2 sm:mr-3">
                  <Image
                    className="h-10 w-10 sm:h-20 sm:w-20 lg:h-28 lg:w-28"
                    image={iconImage}
                    objectFit="contain"
                    alt={iconImageAlt || ''}
                    loading="eager"
                  />
                </div>
              )}
              {title && (
                <h1 className="flex-shrink text-4xl lg:text-5xl font-bold uppercase text-white">
                  {title}
                </h1>
              )}
            </div>
            {description && (
              <div
                className="mt-3 mb-5 text-white drop-shadow font-medium text-lg prose-basic"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}

            <div>
              {button && (
                <Link
                  className="Button --light font-semibold sm:font-medium w-full sm:w-auto justify-center sm:justify-start"
                  item={button}
                >
                  {button.label}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
