import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { CallToAction } from '../components/Sections/CallToAction'
import { VerticalCardsGrid } from '../components/Sections/VerticalCardsGrid'
import { Card } from '../components/Sections/Card'
import { filterCurrentCourses } from '../utils/courses'
import { CoursesGrid } from '../components/CoursesGrid'

type IndexPageProps = PageProps<Queries.IndexPageQuery>

const IndexPage = ({
  data: {
    directus: { page, courses, layout },
  },
}: IndexPageProps) => {
  if (!page) {
    return null
  }

  const currentCourses = filterCurrentCourses(courses)

  return (
    <Layout>
      {page.hero && <CallToAction {...page.hero} />}
      {!!page.intro_cards?.length && (
        <div className="pt-16 max-w-xl mx-auto md:max-w-full">
          <VerticalCardsGrid
            cardImageObjectFit="contain"
            cardImageClassName="h-20 md:h-16 lg:h-24 xl:h-28"
            cardDescriptionClassName="text-base -mx-2 md:text-sm lg:text-base"
            centered={true}
            heading={page.intro_heading}
            cards={page.intro_cards.map(
              (card) => card && card.vertical_card_components_id,
            )}
            imageLoading="eager"
          />
        </div>
      )}
      {!!currentCourses?.length && (
        <CoursesGrid
          heading={page.courses_heading}
          description={page.courses_description}
          courses={currentCourses}
          gridOptions={{
            columns: 2,
            cardImageClassName: 'h-40 lg:h-48',
            cardTitleClassName: 'text-2xl md:text-xl lg:text-2xl',
            cardDescriptionClassName: 'text-gray-600 text-lg line-clamp-3',
          }}
        />
      )}
      {page.sign_up_banner && (
        <CallToAction className="pt-16" {...page.sign_up_banner} />
      )}
      {layout?.about_card && (
        <Card className="py-10 bg-brand-light-gray" {...layout.about_card} />
      )}
      {layout?.contact_banner && (
        <CallToAction className="pt-16" {...layout.contact_banner} />
      )}
    </Layout>
  )
}

export default IndexPage

export { PageHead as Head } from '../components/PageHead'

export const query = graphql`
  query IndexPage {
    directus {
      page: homepage {
        page_title
        meta_description
        hero {
          ...CallToActionComponent
        }
        intro_heading
        intro_cards {
          vertical_card_components_id {
            ...VerticalCardComponent
          }
        }
        courses_heading
        courses_description
        sign_up_banner {
          ...CallToActionComponent
        }
      }
      courses(filter: { status: { _eq: "published" } }, sort: "start_date") {
        ...CourseGridCard
      }
      layout {
        about_card {
          ...CardComponent
        }
        contact_banner {
          ...CallToActionComponent
        }
      }
    }
  }
`
