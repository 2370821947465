import * as React from 'react'
import { PageHeaderProps } from './PageHeader'
import { Seo, SeoProps } from './Seo'
import { PageProps } from 'gatsby'

type PagePropsDirectus = {
  directus: PagePropsDirectusPage
}

type PagePropsDirectusPage = {
  page: PagePropsDirectusPageProps
}

type PagePropsDirectusPageProps = {
  page_header?: PageHeaderProps | null
  page_title?: string | null
  meta_description?: string | null
}

type PageHeadProps = PageProps<PagePropsDirectus> & SeoProps

export const PageHead = ({
  data: {
    directus: { page },
  },
  ...props
}: PageHeadProps) => {
  const pageProps: SeoProps = {}

  if (!page) {
    return <Seo {...props} />
  }

  if (page.page_title) {
    pageProps.title = page.page_title
  } else if (page.page_header?.title) {
    pageProps.title = page.page_header.title
  }

  if (page.meta_description) {
    pageProps.description = page.meta_description
  }

  return <Seo {...{ ...props, ...pageProps }} />
}
