import * as React from 'react'
import { graphql } from 'gatsby'
import { CallToActionHero } from './CallToAction/Hero'
import { CallToActionBanner } from './CallToAction/Banner'
import { CallToActionRightContent } from './CallToAction/RightContent'

type CallToActionProps = DirectusCallToAction & {
  className?: string
  children?: React.ReactNode
}

export type CallToActionComponentProps = {
  className?: string
  backgroundImage: Maybe<DirectusImage>
  backgroundImageAlt: Maybe<Scalars['String']>
  iconImage: Maybe<DirectusImage>
  iconImageAlt: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  button: Maybe<DirectusLink>
  children?: React.ReactNode
}

export const CallToAction = (data: CallToActionProps) => {
  const props: CallToActionComponentProps = {
    className: data.className,
    backgroundImage: data.background_image,
    backgroundImageAlt: data.background_image_alt,
    iconImage: data.icon_image,
    iconImageAlt: data.icon_image_alt,
    title: data.title,
    description: data.description,
    button: data.button_link,
    children: data.children,
  }

  switch (data.type) {
    case 'banner':
      return <CallToActionBanner {...props} />
    case 'right-content':
      return <CallToActionRightContent {...props} />
    case 'hero':
    default:
      return <CallToActionHero {...props} />
  }
}

export const query = graphql`
  fragment CallToActionComponent on DirectusData_call_to_action_components {
    type
    background_image {
      id
      imageFile {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
        }
      }
    }
    background_image_alt
    icon_image {
      id
      imageFile {
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
    icon_image_alt
    title
    description
    button_link {
      ...DirectusLink
    }
  }
`
